<template>
  <div>
    <div class="col-md-4 col-md-offset-4" v-if="twofactor.tempSecret">
      <span v-if="twofactor.tempSecret">
        <p>Scan the QR code or enter the secret in Google Authenticator</p>
        <img :src="twofactor.dataURL" alt="..." class="img-thumbnail">
        <p>Secret - {{twofactor.tempSecret}}</p>
        <p>Type - TOTP</p>
        <div class="form-group">
          <label for="otp">Enter Otp:</label>
          <v-text-field outlined v-model="otp" type="otp" class="form-control" id="otp"></v-text-field>
        </div>
        <button @click="confirm" class="btn btn-default">confirm</button>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      otp: '',
      twofactor: {
        tempSecret: '',
        dataURL: '',
        otpURL: ''
      }
    }
  },
  methods: {
    confirm () {
      axios.post('/auth/twofactor/verify', { token: this.otp })
        .then(() => {
          delete axios.defaults.headers.common.Authorization
          this.$router.push({ name: 'Login' })
        })
        .catch(() => {
          delete axios.defaults.headers.common.Authorization
          this.$router.push({ name: 'Login' })
        })
    }
  },
  computed: {
    tmpLogin () {
      return this.$store.getters.tmpLogin
    }
  },
  created () {
    axios.post('/auth/twofactor/setup')
      .then(res => {
        this.twofactor.tempSecret = res.data.tempSecret
        this.twofactor.dataURL = res.data.dataURL
        this.twofactor.otpURL = res.data.otpURL
      })
  }
}
</script>
